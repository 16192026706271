
            <template>


  <div class="users" style="padding-bottom:100px;">
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new User.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <div class="hidden-sm-and-down">
        <h1 class="Header">Users</h1>
    </div>
    <v-toolbar flat color="white">
        <v-toolbar-title>My Users</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="relatedusersearch"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="relatedusersearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New User</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">New Build</span>
            </v-card-title>

            <v-card-text>

                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.Full_Name" label="FullName"></v-text-field>
                    <v-text-field v-model="editedItem.Name" label="Name"></v-text-field>
                    <v-text-field v-model="editedItem.Surname" label="Surname"></v-text-field>
                      <v-combobox
                        v-model="editedItem.Business_Unit"
                        :items="BusinessUnitArray"
                        item-text="Name"
                        hide-selected
                        label="Search Business Unit"
                        small-chips
                        solo
                      ></v-combobox>
                      <v-combobox
                        v-model="editedItem.Manager"
                        :items="ManagerArray"
                        item-text="Full_Name"
                        hide-selected
                        label="Search Manager"
                        small-chips
                        solo
                      ></v-combobox>
                  </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
<v-toolbar v-if="UsersArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8">Filters</h3><v-spacer></v-spacer>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="BusinessUnitArray"
          v-model="BusinessUnitfilter"
          item-text="Name"
          label="BusinessUnits"
          autowidth
          return-object
          chips
          />
          </div>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="ManagerArray"
          v-model="Managerfilter"
          item-text="Full_Name"
          label="Users"
          autowidth
          return-object
          chips
          />
          </div>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>

<v-layout row class="align-center">
<v-card v-for="card in UsersFilteredandSearched" :key="card.id"
    class="mx-3 my-3"
    min-width="250px"
    max-width="400"
    color='#ffffff'
  >
    <v-card-title>{{ card.fullname }}</v-card-title>

    <v-card-actions>
      <v-btn
        color="orange"
        text :to="'/User/'+card.id"
      >
        View
      </v-btn>

      <v-btn
        color="orange"
        text @click="editItem(card)"
      >
        Edit
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-layout>








  </div>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  data() {
    return {
      profilephotofilesnackbar: false,
      selectedprofilephotoFile: '',
      profilephotolocalURL: '',
      BusinessUnitArray: [],
      BusinessUnitfilter: {Name: ''},
      ManagerArray: [],
      Managerfilter: {Full_Name: ''},
      mainrecordid: '',
      RelatedMasterName: '',
      RelatedMaster: '',
      dialog: false,
      initialize: '',
      relatedusersearch: '',
      headers: [
      {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'id'
        },
          { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedUsersnackbar: false,
      UserEditdialog: false,
      newUserdialog: false,
      UsersArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
        fullname: '',
        name: '',
        surname: '',
        businessunit: {id: '',  name: ''},
        manager: {id: '',  fullname: ''},
        Profile_Photo: '',
      },
      editedItem: {
        id: '',
        fullname: '',
        name: '',
        surname: '',
        businessunit: {id: '',  name: ''},
        manager: {id: '',  fullname: ''},
        Profile_Photo: '',
      },
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "System Admin"
],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [
  "System Admin",
  "CRMEditor"
],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [
  "System Admin"
],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [
  "System Admin"
],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [
  "System Admin"
],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      URLPATHNAME: ''
    }
  },

  created(){
    let currenturl= window.location.pathname
    let urlpath = currenturl.replace(this.$route.params.id,'')
    this.URLPATHNAME = urlpath.split('/').join('')
    this.GetRequestingUser()
      this.GetBusinessUnitsBusinessUnit()
      this.GetUsersManager()

    this.GetRelatedMaster()
  },
  computed: {
    userLoggedIn () {
            return this.$store.getters.user
            },
    formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    UsersSearched() {
        return this.RelatedUsers.filter(User => {
        return User
      })

      },
      RelatedUsers() {
        let RelatedToID = this.$route.params.id
        let currenturl= window.location.pathname
        let urlpath = currenturl.replace(this.$route.params.id,'')
        if (urlpath === '/BusinessUnit/') {
          return this.UsersArray.filter(User => {
          return User.businessunitid === RelatedToID
        })
        }
      },
      UsersFilteredandSearched() {
      return this.UsersSearched.filter(User => {if(this.BusinessUnitfilter.Name){if(User.Business_Unit.id){ return User.Business_Unit.id.includes(this.BusinessUnitfilter.id)}} else {return User}}).filter(User => {if(this.Managerfilter.id){if(User.Manager.id){ return User.Manager.id.includes(this.Managerfilter.id)}} else {return User}})
      },
    },
  methods: {
    ResetFilters() {
      this.BusinessUnitfilter = {Name: ''}
      this.Managerfilter = {Full_Name: ''}
      },
    ResetSearch() {
      this.relatedusersearch = ''
    },

    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRoles = vm.userLoggedIn.rolesarrayQuery
              vm.UserBusinessUnitID = vm.userLoggedIn.Business_Unitid
              console.log(vm.UserBusinessUnitID)
              db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)
             vm.UserRoles.map(role => {
              let alltest = vm.AlllistRoles.find(obj => obj == role)
              let peertest = vm.UnitDownlistRoles.find(obj => obj == role)
              let parenttest = vm.UserUnitlistRoles.find(obj => obj == role)
              let childtest = vm.OwnerlistRoles.find(obj => obj == role)
              if(typeof alltest !== 'undefined'){
                vm.listAll = true
                console.log('Related BU permission is All')
              }
              else if(typeof peertest !== 'undefined'){
                vm.listUnitDown = true
                console.log('Related BU permission is UnitDown')
              }
              else if(typeof parenttest !== 'undefined'){
                vm.listUserUnit = true
                console.log('Related BU permission is UserUnit')
              }
              else if(typeof childtest !== 'undefined'){
                vm.listOwner = true
                console.log('Related BU permission is Child')
              }
             })
             vm.GetUsers(vm.UserBusUnitChildren, vm.UserBusinessUnitID)
           })

            })


          }
      else {
          }
          })
      },

    RoutetoErrorPage(){
        this.$router.replace('/PermissionError')
      },
    
    GetUsers(busunitchildren, busunitid) {
      if(this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){
          this.RoutetoErrorPage()
        }
      var collectionReference = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users')
      console.log(busunitchildren)
      if(this.listAll){
      var query = collectionReference;
      this.UsersCall(query)
      }
      else if(this.listUnitDown){
      var query = collectionReference.where('Business_Unitid', 'in', busunitchildren);
      this.UsersCall(query)
      }
      else if(this.listUserUnit){
      var query = collectionReference.where('Business_Unitid', '==', busunitid);
      this.UsersCall(query)
      }
      else if(this.listOwner){
      var query = collectionReference.where('Business_Unitid', '==', this.UserRecord.id);
      this.UsersCall(query)
      }

  },

  UsersCall(query){
    query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
  },
    FullReset(){
      this.ResetFilters()
      this.ResetSearch()
      this.editedItem = Object.assign({},this.defaultItem)
    },

    AssignEditedItem(clickfrom){
    },

    GetRelatedMaster(){
      let RelatedToID = this.$route.params.id
      let currenturl= window.location.pathname
      let urlpath = currenturl.replace(this.$route.params.id,'')
        if (urlpath === '/BusinessUnit/') {
        this.GetBusinessUnit()
        }
    },
      GetBusinessUnit() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(this.$route.params.id).onSnapshot(snapshot => {
        let masterrecord = snapshot.data()
        this.RelatedMasterName = masterrecord.Name
        this.defaultItem.businessunit = {Name: this.RelatedMasterName, id: this.$route.params.id}
        this.editedItem = this.defaultItem
      })

      },
      onprofilephotoUpload() {
        var storageRef = firebase.storage().ref('User/' + 'profilephoto/'+this.selectedprofilephotoFile.Name);
        var uploadTask = storageRef.put(this.selectedprofilephotoFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.mainrecordid).update({
                    Profile_Photo: url
            })
            this.selectedprofilephotoFile = ''
                }),
                  this.profilephotofilesnackbar = true



      },
      onprofilephotoFileselected(event) {
        this.selectedprofilephotoFile = event.target.files[0]
        this.profilephotolocalURL = URL.createObjectURL(this.selectedprofilephotoFile)
      },



      
      GetBusinessUnitsBusinessUnit() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },

      GetUsersManager() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ManagerArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },








      ViewItem (item) {
        this.$router.replace('/User/'+item.id)
      },

      deleteItem (item) {
        const index = this.UsersArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(item.id).delete() && this.UsersArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.UsersArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },

      save () {
      const EditedUser = {
        fullname: this.editedItem.Full_Name,
        name: this.editedItem.Name,
        surname: this.editedItem.Surname,
        businessunit: this.editedItem.Business_Unit.Name,
        businessunitid: this.editedItem.Business_Unit.id,
        manager: this.editedItem.Manager.Full_Name,
        managerid: this.editedItem.Manager.id,
        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id


      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.mainrecordid).update({
        fullname: EditedUser.Full_Name,
        name: EditedUser.Name,
        surname: EditedUser.Surname,
        businessunit: {Name: this.editedItem.Business_Unit.Name,id: this.editedItem.Business_Unit.id},
        businessunitid: this.editedItem.Business_Unit.id,
        manager: {Full_Name: this.editedItem.Manager.Full_Name,id: this.editedItem.Manager.id},
        managerid: this.editedItem.Manager.id,
              });
              Object.assign(this.UsersArray[this.editedIndex], this.editedItem)
              this.closeUsereditDialog
              this.EditedUsersnackbar = true
        }
         else {
          const NewUser = {
            fullname: EditedUser.Full_Name,
            name: EditedUser.Name,
            surname: EditedUser.Surname,
            businessunit: {Name: this.editedItem.Business_Unit.Name,id: this.editedItem.Business_Unit.id},
            businessunitid: this.editedItem.Business_Unit.id,
            manager: {Full_Name: this.editedItem.Manager.Full_Name,id: this.editedItem.Manager.id},
            managerid: this.editedItem.Manager.id,
          }
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').add(NewUser).then({

          })
        }
        this.close()
      },

  }
}

</script>
  